export default class WpSearchComponent {
    constructor() {
        WpSearchComponent.init()
    }

    static init() {
        const body = 'body'
        const trigger = '[data-trigger="wp-search"]'
        const close = '[data-trigger="wp-search-close"]'
        const element = '[data-element="wp-search"]'

        if ($(trigger).length > 0 && $(element).length > 0) {
            $(trigger).on('click', (e) => {
                e.preventDefault()
                $(body).toggleClass('search-open')

                if ($(body).hasClass('search-open')) {
                    $(element).find('input').focus()
                }
            })

            $(close).on('click', (e) => {
                e.preventDefault()
                $(body).removeClass('search-open')
            })

            $(document).on('keyup', (e) => {
                if (e.keyCode === 27) {
                    $(body).removeClass('search-open')
                }
            })
        }
    }
}
