import NavbarEnum from '../enumerators/navbar.enum'

export default class NavbarComponent {
    static scrollPosition = 0

    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.hover()
        NavbarComponent.burger()
        NavbarComponent.lang()
        NavbarComponent.initWidgetOS()

        const mediaQuery = window.matchMedia('(max-width: 1000px)')
        mediaQuery.addEventListener('change', () => NavbarComponent.resizeWidgetOS())
    }

    static sticky() {
        let lastScrollTop = 0
        let stateOnTop = true

        $(window).on('load scroll', function () {
            const offset = 100 // Navbar default height
            const offsetOnScroll = 100 // Navbar height on scroll
            const navbar = $(NavbarEnum.NAVBAR)
            const scrollTop = $(this).scrollTop()
            let hasBanner = false

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0) {
                hasBanner = true
            }

            // Hidden on scroll comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                $(NavbarEnum.BODY).addClass('header-is-hidden')
                $(NavbarEnum.BODY).removeClass('lang-switcher-open')
            } else {
                $(NavbarEnum.BODY).removeClass('header-is-hidden')
            }

            lastScrollTop = scrollTop

            // On top/on scroll switch
            if (scrollTop > offset && stateOnTop === true) {
                stateOnTop = false
                navbar.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')
            } else if (scrollTop < offsetOnScroll && stateOnTop === false) {
                stateOnTop = true
                navbar.removeClass('navbar--onscroll')
                navbar.addClass('navbar--ontop')
            }
        })
    }

    static hover() {
        if (window.matchMedia('(min-width: 1001px)').matches) {
            $('.menu__item').hover(() => {
                //Set submenu position
                const navbarOffset =
                    $(NavbarEnum.NAVBAR).offset().top + $(NavbarEnum.NAVBAR).outerHeight()
                $(NavbarEnum.BURGER_SUBMENU).offset({ top: navbarOffset })
                $(NavbarEnum.MENU_BACKGROUND).offset({ top: navbarOffset })
            })
        }
    }

    static burger() {
        //Open/Close menu
        $(NavbarEnum.BURGER_MENU_TRIGGER).on('click', () => {
            $(NavbarEnum.BODY).removeClass('header-is-hidden')
            $(NavbarEnum.BODY).toggleClass('menu-open')

            if (!$(NavbarEnum.BODY).hasClass('menu-open')) {
                $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')
                $(NavbarEnum.BODY).removeClass('has-submenu-open')
            }

            //Hide ecomode
            $(NavbarEnum.ECOMODE).addClass('eco-bar--hidden')
        })

        //Open/Close submenu
        $(NavbarEnum.BURGER_MENU_MORE).on('click', function () {
            $(this).next(NavbarEnum.BURGER_SUBMENU).toggleClass('submenu-open')
            $(NavbarEnum.BODY).addClass('has-submenu-open')
        })

        //Close submenu
        $(NavbarEnum.BURGER_MENU_PREV).on('click', (e) => {
            e.preventDefault()
            $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')
            $(NavbarEnum.BODY).removeClass('has-submenu-open')
        })

        //Close menu
        $(NavbarEnum.BURGER_CLOSE).on('click', (e) => {
            e.preventDefault()
            $(NavbarEnum.BODY).removeClass('has-submenu-open')
            $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')

            setTimeout(() => {
                $(NavbarEnum.BODY).removeClass('menu-open')
            }, 300)
        })
    }

    static lang() {
        $(NavbarEnum.LANG_SWITCHER).on('click', () => {
            $(NavbarEnum.BODY).toggleClass('lang-switcher-open')
        })

        $(document).click((event) => {
            if (!$(event.target).closest(NavbarEnum.LANG_SWITCHER).length) {
                $(NavbarEnum.BODY).removeClass('lang-switcher-open')
            }
        })
    }

    static initWidgetOS() {
        if (window.matchMedia('(max-width: 1000px)').matches) {
            const cart = $(NavbarEnum.OS_CART_TOOLS).html()
            $(NavbarEnum.OS_CART_TOOLS).html('')
            $(NavbarEnum.OS_CART_BOTTOMBAR).html(cart)
        }
    }

    static resizeWidgetOS() {
        if (window.matchMedia('(max-width: 1000px)').matches) {
            const cart = $(NavbarEnum.OS_CART_TOOLS).html()
            $(NavbarEnum.OS_CART_TOOLS).html('')
            $(NavbarEnum.OS_CART_BOTTOMBAR).html(cart)
        } else {
            const cart = $(NavbarEnum.OS_CART_BOTTOMBAR).html()
            $(NavbarEnum.OS_CART_BOTTOMBAR).html('')
            $(NavbarEnum.OS_CART_TOOLS).html(cart)
        }
    }
}
