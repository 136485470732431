export default class NavbarEnum {
    static BODY = 'body'
    static NAVBAR = '[data-element="navbar"]'
    static LOGO = '[data-element="navbar-logo"]'
    static ECOMODE = '[data-id="eco-bar"]'
    static MENU_ITEM = '.menu__item--has-submenu'
    static MENU_BACKGROUND = '[data-element="menu-background"]'
    static BURGER_MENU_TRIGGER = '[data-trigger="burger-menu-toggle"]'
    static BURGER_MENU_MORE = '[data-trigger="burger-menu-more"]'
    static BURGER_MENU_PREV = '[data-trigger="burger-menu-prev"]'
    static BURGER_SUBMENU = '[data-element="burger-submenu"]'
    static BURGER_CLOSE = '[data-trigger="burger-menu-submenu-close"]'
    static SEARCH_TRIGGER = '[data-hc-modal][href="#modal-wp-search"]'
    static SEARCH_MODAL_INPUT = '#modal-wp-search input[name="s"]'
    static LANG_SWITCHER = '[data-trigger="lang-switcher"]'
    static LANG_SWITCHER_LIST = '[data-element="lang-switcher-list"]'
    static OS_CART_TOOLS = '[data-element="os-cart-tools"]'
    static OS_CART_BOTTOMBAR = '[data-element="os-cart-bottombar"]'
}
