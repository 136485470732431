export default class BreadcrumbsComponent {
    constructor() {
        BreadcrumbsComponent.show()
    }

    static show() {
        const breadcrumbs = '#breadcrumbs'

        if (breadcrumbs.length > 0) {
            $(breadcrumbs).on('click', function () {
                if (window.matchMedia('(max-width: 1000px)').matches) {
                    $(this).toggleClass('breadcrumbs--show')
                }
            })
        }
    }
}
